<template>
  <UiTransition
    v-bind="computedProperties.transition"
    @after-enter="afterDrawerEnter"
    @after-leave="clearAllBodyScrollLocks"
    @before-leave="beforeDrawerLeave"
  >
    <div
      v-show="open"
      ref="drawerRef"
      class="fixed z-drawer"
      :style="{
        paddingTop: 'env(safe-area-inset-top)',
        paddingBottom: 'env(safe-area-inset-bottom)',
      }"
      data-el="ui-drawer"
      v-bind="$attrs"
      :class="[
        computedProperties.css,
        props.variant !== 'blank' ? variant.css : '',
        $attrs.class,
        $attrs.staticClass,
      ]"
      @keydown.esc="onClose"
    >
      <slot />
    </div>
  </UiTransition>
  <UiTransition name="fade">
    <div
      v-show="open && level === 0"
      class="fixed inset-0 z-underDrawer bg-primary opacity-40"
      data-el="ui-drawer"
      @click="onClose"
    />
  </UiTransition>
</template>

<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'
import type { TransitionProps } from 'vue'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import UiTransition from '../UiTransition/UiTransition.vue'

interface UiDrawerInterface {
  side: 'left' | 'top' | 'right' | 'bottom'
  open: boolean
  level?: number
  transition?: TransitionProps | null
  variant?: 'blank' | 'default'
  width?: string
  height?: string
}

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<UiDrawerInterface>(), {
  side: 'left',
  open: false,
  level: 0,
  width: 'w-1/3',
  height: 'h-1/3',
  transition: null,
  variant: 'default',
})

const emit = defineEmits(['update:open', 'close'])

const drawerRef = ref<InstanceType<any> | undefined>(null)

const {
  hasFocus: isFocusTrapActivated,
  activate: activateFocusTrap,
  deactivate: deactivateFocusTrap,
} = useFocusTrap(drawerRef, {
  clickOutsideDeactivates: true,
  // eslint-disable-next-line nuxt/prefer-import-meta
  fallbackFocus: process.client ? document?.body : null,
})

const computedProperties = computed(() => {
  if (props.side === 'right') {
    return {
      css: 'h-full top-0 right-0',
      transition: props.transition || {
        name: 'slide-right-in-left-out',
      },
    }
  }

  if (props.side === 'bottom') {
    return {
      css: 'w-full bottom-0 left-0 right-0 top-0',
      transition: props.transition || {
        name: 'slide-top-in-bottom-out',
      },
    }
  }

  if (props.side === 'top') {
    return {
      css: 'w-full top-0 left-0 right-0',
      transition: props.transition || {
        name: 'slide-bottom-in-top-out',
      },
    }
  }

  // else default is left
  return {
    css: 'h-full top-0 left-0',
    transition: props.transition || {
      name: 'slide-left-in-right-out',
    },
  }
})

const variant = computed(() => {
  const css = ['bg-white shadow-drawer overflow-auto']
  if (props.side === 'left' || props.side === 'right') {
    css.push(props.width)
  }
  else {
    css.push(props.height)
  }
  return {
    css,
  }
})

function scrollLock() {
  if (drawerRef.value) {
    disableBodyScroll(drawerRef.value, {
      reserveScrollBarGap: true,
      allowTouchMove: () => true,
    })
  }
}

function afterDrawerEnter() {
  scrollLock()
  nextTick(() => {
    activateFocusTrap()
  })
}

function beforeDrawerLeave() {
  if (isFocusTrapActivated) {
    deactivateFocusTrap()
  }
}

function onClose() {
  emit('update:open', false)
  emit('close')
}
</script>
